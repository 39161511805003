.ant-btn-sm {
  line-height: 1.3;
  align-items: center;
  min-width: 90px !important;
  font-size: 12px;
}

.ant-btn {
  @extend .ellipsis;
  min-width: 130px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  @include media('<phone') {
    min-width: 90px;
  }

  @include media('<tablet') {
    display: inline-block;
    font-size: 12px;
    padding: 4px 10px;
  }

  >span {
    @include media('<phone') {
      display: inline;
    }
  }

  .anticon {
    font-size: 21px;

    @include media('<phone') {
      font-size: 16px;
    }

    @include media('<tablet') {
      font-size: 14px;
    }

    &.anticon-plus {
      vertical-align: middle;
      margin-top: 2px;
      font-size: 18px;
    }
  }

  .arrow-down {
    transition: transform 0.3s;
    margin-right: -6px;
  }
}

.ant-btn-default {
  color: $white !important;
  border-color: $white;

  &:hover {
    border-color: $dark-pink;
  }

  &:focus {
    border-color: #CD0170;
  }

  &[disabled] {
    background-color: transparent;
    color: darken($white, 40%) !important;

    &:hover {
      background-color: transparent;
    }
  }
}

a.ant-btn {
  padding-top: 5px !important;
  line-height: 1.2 !important;

  .anticon-plus {
    margin-right: 6px;
  }
}

.ant-btn-primary {
  &:hover {
    background-color: $dark-pink;
  }

  &:focus {
    background-color: #CD0170;
  }

  &[disabled] {
    background-color: #7D0144;

    &:hover {
      background-color: #7D0144;
    }
  }
}

.ant-btn-secondary {
  background-color: $light-gray;
  border-color: $light-gray;

  &:hover {
    background-color: #8D9296;
    border-color: #8D9296;
  }

  &:focus {
    background-color: #6D7276;
    border-color: #6D7276;
  }

  &[disabled] {
    background-color: #424548;
    color: $charleston-green !important;

    &:hover {
      background-color: #424548;
    }
  }

  &.bg-light-gray {
    color: $charleston-green !important;
    background-color: rgba(34, 43, 51, 0.15);
    border-color: transparent;

    &:hover {
      background-color: rgba(34, 43, 51, 0.20);
    }
  }
}

.btn-add {
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: $primary-color;
  transition: color 0.3s;
  margin-bottom: 13px;

  &:hover {
    color: lighten($primary-color, 5%);
  }
}