@import 'scss/vendors/include-media';

.edit-model-form {

    .holder-form {

        @include media('>=tablet') {
            display: flex;
        }

        .select-model-modal-button {


            @include media('>=tablet') {
                margin-right: 20px;
            }
        }

        .ant-btn {
            min-width: 140px;
            margin-top: 25px;

            @include media('>=tablet') {}

            .anticon.anticon-plus {
                margin-left: -10px;
            }
        }
    }
}

.last-minute-form {
    .holder-form {
        flex-wrap: wrap;
    }
}