.page-last-minute {


    .no-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        height: 100%;

        .icon-no-jobs {
            font-size: 111px;
            margin-bottom: 30px;
        }

        .text {
            color: white;
        }
    }
}