@import 'scss/vendors/include-media';
@import 'scss/base/variables';




.sidebar-menu {
    // font-weight: 600;
    text-transform: capitalize;
    padding: 10px 0;
    margin-bottom: 20px;

    .anticon {
        color: inherit !important;
        font-size: 27px !important;
        margin-right: 17px;
        transition: none !important;
    }

    .ant-menu-item-only-child {
        display: inline-block;
        padding-left: 35px !important;
        margin: 0 !important;

        &.border-menu-item {
            margin-top: 47px !important;
            border: solid $primary-color;
            border-width: 1px 0  1px 0;

            &.ant-menu-item-selected {
                background-color: $primary-color !important;


                a {
                    &:hover {
                        color: $white !important;
                    }
                }
            }
        }

         a {
            display: inline-flex;
            align-items: center;
            margin-top: 5px;

            &:hover {
                color: $primary-color !important;
            }
        }
    }

}



.ant-layout-sider-zero-width-trigger {

    @include media('<widescreen') {
        top: 0px !important;
    }
}

.ant-layout-sider {

    @include media('<widescreen') {
        position: fixed !important;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1001;
    }
}