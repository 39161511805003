@import 'scss/base/variables';
@import 'scss/vendors/include-media';
@import 'scss/base/mixins';
@import 'scss/base/helpers';


.page {
  display: flex;
  flex-direction: column;
  flex: auto;

  @include media('>=desktop') {
    margin: 0 31px 30px;
  }

  .page-header {
    padding: 0 15px;
    margin-bottom: 10px;

    @include media('>=desktop') {
      padding: 0 30px;
    }

    .right-bar {
      display: inline-flex;
      flex-wrap: wrap;
      margin-left: auto;
    }
  }

  .page-header-top-bar {
    margin: 0 0 5px;

    @include media('>=desktop') {
      display: flex;
      align-items: flex-start;
    }

    h1,
    h2,
    h3 {

      .ant-skeleton-paragraph {
        display: none;
      }
    }

    >* {
      margin-bottom: 13px;
    }

    .right-bar {
      min-width: 100%;
      padding-top: 5px;

      @include media('<desktop') {
        margin: 0 -7px 6px;
      }

      @include media('>=desktop') {
        min-width: 35%;
        justify-content: end;
        margin-bottom: 6px;
      }

      >* {
        margin: 0 7px 7px;

        @include media('<tablet') {
          width: calc(50% - 14px);
        }
      }
    }

    .file-upload {
      .ant-upload.ant-upload-drag{
        display: block;
      }

      .ant-btn {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .page-navbar {
    display: flex;
    flex-wrap: wrap;

    .page-links {
      display: inline-flex;
      flex-wrap: wrap;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;

      >li {
        margin-right: 18px;

        @include media('>=tablet') {
          font-size: 16px;
          margin-right: 25px;
        }

        &.active {
          a {
            color: $primary-color !important;

            &:before {
              left: 0;
              right: 0;
              visibility: visible !important;
              opacity: 1 !important;
            }
          }
        }

        a {
          @extend %underline;
          color: $lighten-gray;
          padding: 0;
          transition: color .3s ease;

          @include media('>=500px') {
            padding: 0 0 10px;
          }

          &:hover {
            color: $white;
          }

          &:before {
            height: 2px;
            bottom: 0;
            z-index: 1;
            opacity: 0 !important;
            visibility: hidden !important;

            @include media('>=500px') {
              bottom: -3px;
            }
          }
        }
      }
    }

    .right-bar {

      >* {
        margin: 0 7px 0;
      }
    }
  }

  .page-content {
    position: relative;
    background-color: $dark-grey;
    height: 100%;
    padding: 15px;

    @include media('>=desktop') {
      border: 1px solid $grey;
      border-radius: 15px;
      padding: 20px 18px;
    }

    @include media('>=1601px') {
      padding: 20px 30px;
    }

    &.tabs-indent {
      margin-top: 38px;
    }

    .col-left {
      @include media('<desktop') {
        order: 2;
      }
    }

    .col-right {
      @include media('<desktop') {
        order: 1;
      }
    }
  }
}