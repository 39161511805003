.tag-main.site-tag-plus {
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  color: #FFF !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.edit-tag {
  user-select: none;

  .ant-tag-close-icon {
    font-size: 13px;
  }
}
.tag-input {
  max-width: 100px;
  margin-right: 8px;
  vertical-align: top;
}