@import 'scss/base/variables';
@import 'scss/vendors/include-media';

.my-profile-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;

  .profile-info {
    @include media('<tablet') {
      display: none;
    }
  }


  .anticon-down {
    margin-left: 6px;
  }

  .profile-icon {
    font-size: 18px;
    margin-right: 2px;

    @include media('>=tablet') {
      font-size: 24px;
      margin-right: 7px;
    }
  }
}

.dropdown-profile {
  .ant-dropdown-menu-item {
    background-color: transparent !important;

    .ant-dropdown-menu-title-content {
      color: $charleston-green;

      &:hover {
        color: $primary-color;
      }
    }
  }
}