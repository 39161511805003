.usergroup-form{

    .ant-checkbox-wrapper{
        color: #ffffff;
        text-transform: inherit;
    }

    .ant-checkbox-wrapper-disabled .ant-checkbox + span{
        color: #BDBFC2;
    }

    .ant-select-selection-item{
        display: flex;
        align-items: center;
    }

}

.ant-select-item-option-content{
    display: flex;
    align-items: center;
}

.color-box{
    height: 16px;
    width: 16px;
    display: block;
    border-radius: 3px;
    margin-right: 10px;
}

